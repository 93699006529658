<template>
    <div>
        <!-- <div class="alert alert-warning">Unfortunately, you don't have access to this feature.  Talk to your superior for more information.</div>
        <router-link class="btn btn-warning" to="/visit">OK</router-link> -->
        <div>Access Restricted</div>
    </div>
</template>

<script>
    export default {
        name: 'Restricted',
    }
</script>